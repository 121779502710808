import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Jitsi from 'components/Jitsi/Jitsi';
import { broadCastStorage } from 'helpers/utils';

interface IBroadCast {}

const BroadCast: FunctionComponent<IBroadCast> = (props: IBroadCast) => {
  const user = JSON.parse(localStorage.getItem('user') || '');
  const history = useHistory();
  const location: any = useLocation();
  const [broadCastItem, setBroadCastItem] = useState<any>({});

  useEffect(() => {
    setBroadCastItem(location.state.selectedBroadCast);
  }, []);

  const handleClose = () => {
    broadCastStorage.clear();
    history.push('/conversations-nearby');
    window.location.reload();
  };

  return (
    <Jitsi handleClose={handleClose} broadCast={broadCastItem} user={user} />
  );
};

export default BroadCast;
