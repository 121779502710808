import asyncActionType from 'utils/reduxActions';

export const FILTER_ACTIONS = {
  VERIFY_EMAIL: asyncActionType('[SOCIAL_PLAY]VERIFY_EMAIL'),
  VERIFY_EMAIL_CALL: '[SOCIAL_PLAY]VERIFY_EMAIL_CALL',

  VERIFY_OTP: asyncActionType('[SOCIAL_PLAY]VERIFY_OTP'),
  VERIFY_OTP_CALL: '[SOCIAL_PLAY]VERIFY_OTP_CALL',

  LOGIN: asyncActionType('[SOCIAL_PLAY]LOGIN'),
  LOGIN_CALL: '[SOCIAL_PLAY]LOGIN_CALL',

  CREATE_ROOM: asyncActionType('[SOCIAL_PLAY]CREATE_ROOM'),
  CREATE_ROOM_BEGIN: '[SOCIAL_PLAY]CREATE_ROOM_BEGIN',

  CREATE_ROOM_FETCH: asyncActionType('[SOCIAL_PLAY]CREATE_ROOM_FETCH'),
  CREATE_ROOM_FETCH_BEGIN: '[SOCIAL_PLAY]CREATE_ROOM_FETCH_BEGIN',

  ROOM_LIST: asyncActionType('[SOCIAL_PLAY]ROOM_LIST'),
  ROOM_LIST_BEGIN: '[SOCIAL_PLAY]ROOM_LIST_BEGIN',

  JOIN_ROOM: asyncActionType('[SOCIAL_PLAY]JOIN_ROOM'),
  JOIN_ROOM_BEGIN: '[SOCIAL_PLAY]JOIN_ROOM_BEGIN',

  INVITE_PEOPLE: asyncActionType('[SOCIAL_PLAY]INVITE_PEOPLE'),
  INVITE_PEOPLE_BEGIN: '[SOCIAL_PLAY]INVITE_PEOPLE_BEGIN',

  FEED_BACK: asyncActionType('[SOCIAL_PLAY]FEED_BACK'),
  FEED_BACK_BEGIN: '[SOCIAL_PLAY]FEED_BACK_BEGIN',

  NOTIFICATION_SETTING: asyncActionType('[SOCIAL_PLAY]NOTIFICATION_SETTING'),
  NOTIFICATION_SETTING_BEGIN: '[SOCIAL_PLAY]NOTIFICATION_SETTING_BEGIN',

  UPDATE_NOTIFICATION_SETTING: asyncActionType('[SOCIAL_PLAY]UPDATE_NOTIFICATION_SETTING'),
  UPDATE_NOTIFICATION_SETTING_BEGIN: '[SOCIAL_PLAY]UPDATE_NOTIFICATION_SETTING_BEGIN',

  DATE_TIME_SETTING: asyncActionType('[SOCIAL_PLAY]DATE_TIME_SETTING'),
  DATE_TIME_SETTING_BEGIN: '[SOCIAL_PLAY]DATE_TIME_SETTING_BEGIN',

  UPDATE_DATE_TIME_SETTING: asyncActionType('[SOCIAL_PLAY]UPDATE_DATE_TIME_SETTING'),
  UPDATE_DATE_TIME_SETTING_BEGIN: '[SOCIAL_PLAY]UPDATE_DATE_TIME_SETTING_BEGIN',

  UPDATE_PROFILE: asyncActionType('[SOCIAL_PLAY]UPDATE_PROFILE'),
  UPDATE_PROFILE_BEGIN: '[SOCIAL_PLAY]UPDATE_PROFILE_BEGIN',

  DELETE_PROFILE: asyncActionType('[SOCIAL_PLAY]DELETE_PROFILE'),
  DELETE_PROFILE_BEGIN: '[SOCIAL_PLAY]DELETE_PROFILE_BEGIN',

  CREATE_LOCATION: asyncActionType('[SOCIAL_PLAY]CREATE_LOCATION'),
  CREATE_LOCATION_BEGIN: '[SOCIAL_PLAY]CREATE_LOCATION_BEGIN',

  CREATE_PUBLIC_PROFILE: asyncActionType('[SOCIAL_PLAY]CREATE_PUBLIC_PROFILE'),
  CREATE_PUBLIC_PROFILE_BEGIN: '[SOCIAL_PLAY]CREATE_PUBLIC_PROFILE_BEGIN',

  NEARBY_ROOMS: asyncActionType('[SOCIAL_PLAY]NEARBY_ROOMS'),
  NEARBY_ROOMS_BEGIN: '[SOCIAL_PLAY]NEARBY_ROOMS_BEGIN',

  ///////////////////////////////////////////////////////

  AUTHENTICATED_USER: '[SOCIAL_PLAY]AUTHENTICATED_USER',
  AUTH_LOGOUT_BEGIN: '[SOCIAL_PLAY]AUTH_LOGOUT_BEGIN',
  AUTH_LOGOUT: '[SOCIAL_PLAY]AUTH_LOGOUT',
  SIGNUP: asyncActionType('[SOCIAL_PLAY]SIGNUP'),
  SIGNUP_CALL: '[SOCIAL_PLAY]SIGNUP_CALL',

  SET_REGISTRATION_CONFIRMATION: '[SOCIAL_PLAY]SET_REGISTRATION_CONFIRMATION',

  REGISTERATION_CONFIRMATION_BEGIN: '[SOCIAL_PLAY]REGISTERATION_CONFIRMATION_BEGIN',
  REGISTERATION_CONFIRMATION: asyncActionType('[SOCIAL_PLAY]REGISTERATION_CONFIRMATION'),

  REGISTRATION_CODE_RESEND_BEGIN: '[SOCIAL_PLAY]REGISTRATION_CODE_RESEND_BEGIN',
  REGISTRATION_CODE_RESEND: asyncActionType('[SOCIAL_PLAY]REGISTRATION_CODE_RESEND'),

  RESTORE_PASSWORD_CALL: '[SOCIAL_PLAY]RESTORE_PASSWORD_CALL',
  RESTORE_PASSWORD: asyncActionType('[SOCIAL_PLAY]RESTORE_PASSWORD'),

  RESET_ERROR_MESSAGE: '[SOCIAL_PLAY]RESET_ERROR_MESSAGE',

  UPLOAD_PROFILE_BEGIN: '[SOCIAL_PLAY]UPLOAD_PROFILE_BEGIN',
  UPLOAD_PROFILE: asyncActionType('[SOCIAL_PLAY]UPLOAD_PROFILE'),

  SEARCH_STATION_BEGIN: '[SOCIAL_PLAY]SEARCH_STATION_BEGIN',
  SEARCH_STATION: asyncActionType('[SOCIAL_PLAY]SEARCH_STSEARCH_STATIONATION_BEGIN'),

  CREATE_BROADCAST_BEGIN: '[SOCIAL_PLAY]CREATE_BROADCAST_BEGIN',
  CREATE_BROADCAST: asyncActionType('[SOCIAL_PLAY]CREATE_BROADCAST'),
  BROADCAST_CREATED_ON_FIREBASE: '[SOCIAL_PLAY]BROADCAST_CREATED_ON_FIREBASE',

  JOIN_STREAM_BEGIN: '[SOCIAL_PLAY]JOIN_STREAM_BEGIN',
  JOIN_STREAM: asyncActionType('[SOCIAL_PLAY]JOIN_STREAM'),

  ACTIVE_BROADCASTS_CALL: '[SOCIAL_PLAY]ACTIVE_BROADCASTS_CALL',

  SET_SELECTED_BROADCAST: '[SOCIAL_PLAY]SET_SELECTED_BROADCAST',

  GET_USER: '[SOCIAL_PLAY]GET_USER',
};

export const FETCH_STATUS = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export default {
  ...FILTER_ACTIONS,
};

export const LOADER_STATUS = {
  NA: -1,
  PENDING: 1,
  COMPLETE: 0,
};


