import { fromJS } from 'immutable';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import ACTIONS, { FETCH_STATUS } from 'store/constants';
import { getErrorMessage } from '../../helpers/utils';

const initialState: any = fromJS({
  user: getItemFromLocalStorage('user') || {},
  loading: false,
  authError: '',
  error: '',
  step: 1,
  isConfirm: false,
  message: '',
  resendLoading: false,
  restoreEmail: '',
  isEmailVerified: false,
  emailVerificationError: '',
  isOTPVerified: false,
  otpVerificationError: '',
  updateProfile: '',
});

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {

    case ACTIONS.SIGNUP.PENDING:
    case ACTIONS.SIGNUP.SUCCESS: {
      return state.set('loading', false).set('error', '').set('user', action.data);
    }
    case ACTIONS.SIGNUP.ERROR: {
      return state.set('loading', false).set('error', getErrorMessage(action.error)).set('isOTPVerified', false);
    }

    case ACTIONS.LOGIN.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.LOGIN.SUCCESS: {
      return state.set('loading', false).set('error', '').set('user', action.data);
    }
    case ACTIONS.LOGIN.ERROR: {
      return state.set('loading', false).set('authError', JSON.parse(action.error.message).detail);
    }

    case ACTIONS.VERIFY_EMAIL.PENDING: {
      return state.set('loading', action.status === FETCH_STATUS.LOADING);
    }
    case ACTIONS.VERIFY_EMAIL.SUCCESS: {
      return state.set('loading', false).set('error', '').set('isEmailVerified', true);
    }
    case ACTIONS.VERIFY_EMAIL.ERROR: {
      return state.set('loading', false).set('emailVerificationError', getErrorMessage(action.error));
    }

    case ACTIONS.VERIFY_OTP.PENDING: {
      return state.set('loading', action.status === FETCH_STATUS.LOADING);
    }
    case ACTIONS.VERIFY_OTP.SUCCESS: {
      return state.set('loading', false).set('error', '').set('isOTPVerified', true);
    }
    case ACTIONS.VERIFY_OTP.ERROR: {
      return state.set('loading', false).set('otpVerificationError', getErrorMessage(action.error));
    }

    case ACTIONS.UPDATE_PROFILE.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.UPDATE_PROFILE.SUCCESS: {
      return state.set('loading', false)
        .set('error', '')
        .set('isProfileUpdated', true)
        .set('updateProfile', action.data.message)
        .set('user', JSON.parse(localStorage.getItem('user') || ''));
    }
    case ACTIONS.UPDATE_PROFILE.ERROR: {
      return state.set('loading', false).set('error', getErrorMessage(action.error));
    }

    case ACTIONS.CREATE_PUBLIC_PROFILE.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.CREATE_PUBLIC_PROFILE.SUCCESS: {
      return state.set('loading', false)
        .set('error', '')
        .set('publicProfile', action)
    }
    case ACTIONS.CREATE_PUBLIC_PROFILE.ERROR: {
      return state.set('loading', false).set('error', getErrorMessage(action.error));
    }

    case ACTIONS.REGISTRATION_CODE_RESEND.PENDING: {
      return state.set('resendLoading', FETCH_STATUS.LOADING === action.status);
    }
    case ACTIONS.REGISTRATION_CODE_RESEND.SUCCESS: {
      return state.set('message', 'Code Send Successfully').set('resendLoading', false);
    }
    case ACTIONS.REGISTRATION_CODE_RESEND.ERROR: {
      return state.set('message', action.error).set('resendLoading', false);
    }

    case ACTIONS.REGISTERATION_CONFIRMATION.PENDING: {
      return state.set('isRegisterConfirm', true);
    }
    case ACTIONS.REGISTERATION_CONFIRMATION.SUCCESS: {
      return state.set('user', action.data).set('isRegisterConfirm', false);
    }
    case ACTIONS.REGISTERATION_CONFIRMATION.ERROR: {
      return state.set('isRegisterConfirm', false).set('error', action.error);
    }

    case ACTIONS.RESTORE_PASSWORD.PENDING: {
      return state.set('loading', true).set('restoreEmail', action.data);
    }
    case ACTIONS.RESTORE_PASSWORD.SUCCESS: {
      return state.set('loading', false);
    }
    case ACTIONS.RESTORE_PASSWORD.ERROR: {
      return state.set('loading', false).set('restoreError', action.error).set('restoreEmail', '');
    }

    case ACTIONS.AUTH_LOGOUT: {
      return state.set('user', null);
    }
    case ACTIONS.RESET_ERROR_MESSAGE: {
      return state.set('loading', false).set('error', '');
    }
    case ACTIONS.AUTHENTICATED_USER: {
      return state.set('user', action.data);
    }
    case ACTIONS.SET_REGISTRATION_CONFIRMATION: {
      return state.set('isConfirm', action.payload);
    }

    default: {
      return state;
    }
  }
}
