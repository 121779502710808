import ACTIONS, { FETCH_STATUS } from 'store/constants';
import {
  IInvitePeopleParams,
  IDateTimeParams,
  ILocationParams,
  IFeedBackParams,
} from 'modules/params/param.type';

export const invitePeopleRequest = (payload: IInvitePeopleParams) => ({
  type: ACTIONS.INVITE_PEOPLE_BEGIN,
  payload,
});

export const invitePeople = {
  pending: {
    type: ACTIONS.INVITE_PEOPLE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.INVITE_PEOPLE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.INVITE_PEOPLE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const feedBackRequest = (payload: IFeedBackParams) => ({
  type: ACTIONS.FEED_BACK_BEGIN,
  payload,
});

export const feedBack = {
  pending: {
    type: ACTIONS.FEED_BACK.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.FEED_BACK.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FEED_BACK.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const notificationSettingRequest = () => ({
  type: ACTIONS.NOTIFICATION_SETTING_BEGIN,
});

export const notificationSetting = {
  pending: {
    type: ACTIONS.NOTIFICATION_SETTING.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.NOTIFICATION_SETTING.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.NOTIFICATION_SETTING.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateNotificationSettingRequest = () => ({
  type: ACTIONS.UPDATE_NOTIFICATION_SETTING_BEGIN,
});

export const updateNotificationSetting = {
  pending: {
    type: ACTIONS.UPDATE_NOTIFICATION_SETTING.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UPDATE_NOTIFICATION_SETTING.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_NOTIFICATION_SETTING.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const dateTimeSettingRequest = () => ({
  type: ACTIONS.DATE_TIME_SETTING_BEGIN,
});

export const dateTimeSetting = {
  pending: {
    type: ACTIONS.DATE_TIME_SETTING.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.DATE_TIME_SETTING.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DATE_TIME_SETTING.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateDateTimeSettingRequest = (payload: IDateTimeParams) => ({
  type: ACTIONS.UPDATE_DATE_TIME_SETTING_BEGIN,
  payload,
});

export const updateDateTimeSetting = {
  pending: {
    type: ACTIONS.UPDATE_DATE_TIME_SETTING.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UPDATE_DATE_TIME_SETTING.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_DATE_TIME_SETTING.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createLocationRequest = (payload: ILocationParams) => ({
  type: ACTIONS.CREATE_LOCATION_BEGIN,
  payload,
});

export const createLocation = {
  pending: {
    type: ACTIONS.CREATE_LOCATION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CREATE_LOCATION.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_LOCATION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
