import React, { FC, useEffect, useState } from 'react';
import Styles from 'components/chatSideBar/chatParticipants/ChatParticipants.module.scss';
import superHostKey from 'assets/images/superhost_user_key.png';
import { IMAGE_URL } from 'store/services/URL';

interface ChatParticipantsProps {
  isSuperHost?: boolean;
  offLineImageCss?: boolean;
  itemIndex: boolean;
  handleChangeIndex: Function;
  id: number;
  item: any;
}

const ChatParticipants: FC<ChatParticipantsProps> = (props: ChatParticipantsProps) => {
  const { isSuperHost, offLineImageCss, itemIndex, handleChangeIndex, item } = props;
  const [avatar, setAvatar] = useState('');
  const [name, setName] = useState('');
  const getAdmin = () => {
    const host = item.host;
    setAvatar(host.profile);
    setName(host.name);
  };
  useEffect(() => {
    getAdmin();
  }, []);
  return (
    <li>
      <div
        className={`${Styles.listingInner} ${itemIndex ? Styles.activeChat : ''}`}
        onKeyPress={() => handleChangeIndex(item)}
        onClick={() => handleChangeIndex(item)}
        tabIndex={0}
        role="button"
      >
        <div className={Styles.userImageWrapper}>
          <img
            src={`${IMAGE_URL}${avatar}`}
            alt="card img"
            className={`${Styles.userImage} ${offLineImageCss ? Styles.offlineUserImage : ''}`}
          />
          {isSuperHost && <img src={superHostKey} alt="super host" className={Styles.superHostImg} />}
        </div>
        <div className={Styles.userDetails}>
          <div className={Styles.userInner}>
            <h5>{item.topic}</h5>
            <p>
              {name}
              &lsquo;s Room
            </p>
          </div>
          {/*<div className={Styles.notifications}>*/}
          {/*  <span className={`badge badge-pill ${Styles.badgeDanger}`}>2</span>*/}
          {/*</div>*/}
        </div>
      </div>
    </li>
  );
};
export default ChatParticipants;
