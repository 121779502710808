// export const BASE_URL = 'https://mashghol.com/';
// export const BASE_URL = 'http://localhost:5000/';

// export const BASE_URL = 'https://api.soundbirdapp.com/api/v1/';
export const BASE_URL = 'https://api.socialplay.com/';

export const URL_SEND_VERIFICATION_EMAIL = `${BASE_URL}accounts/send-verification-email`;
export const URL_VERIFY_EMAIL = `${BASE_URL}accounts/verify-email`;
export const URL_LOGIN = `${BASE_URL}api/token/`;
export const URL_TOKEN_REFRESH = `${BASE_URL}api/token/refresh/`;
export const URL_SIGNUP = `${BASE_URL}accounts/signup`;

export const URL_FORGOT_PASSWORD = `${BASE_URL}accounts/forgot-password`;

export const URL_DATE_TIME_FORMAT = `${BASE_URL}accounts/date-time-format`;
export const URL_INVITE_PEOPLE = `${BASE_URL}accounts/invite-people`;
export const URL_NOTIFICATION_SETTING = `${BASE_URL}accounts/notification-setting`;
export const URL_PROFILE = `${BASE_URL}accounts/profile`;
export const URL_CREATE_ROOM = `${BASE_URL}rooms`;
export const URL_ROOM_LIST = `${BASE_URL}rooms/list`;
export const URL_ROOMS_NEARBY = `${BASE_URL}rooms/nearby`;
export const URL_JOIN_ROOM = `${BASE_URL}rooms/join/`;
export const URL_PUBLIC_PROFILE = `${BASE_URL}accounts/public-profile`;
export const URL_SET_LOCATION = `${BASE_URL}accounts/set-location`;


export const URL_ROOMS_REMOVE_GUEST = `${BASE_URL}rooms/remove-guest`;
export const URL_ROOMS_LOGGED_TIME = `${BASE_URL}rooms/logged-time`;
// export const URL_ROOMS_JOIN_REQUEST_ID = `${BASE_URL}rooms/join-request/{id}`;
// export const URL_ROOMS_JOIN_REQUEST = `${BASE_URL}rooms/join-request`;
// export const URL_ROOMS_ACCEPT_REQUEST = `${BASE_URL}rooms/accept-request/{id}`;
export const URL_NOTIFICATION = `${BASE_URL}notification​/notification`;
export const URL_ACCOUNT_FOLLOW_PROFILE = `${BASE_URL}accounts/follow-profile/{id}`;
export const URL_ACCOUNT_DEVICES = `${BASE_URL}accounts/devices`;
export const URL_ACCOUNT_DEVICES_REGISTRATION = `${BASE_URL}accounts/devices/{registration_id}/`;
export const URL_BILLING_DETAILS = `${BASE_URL}accounts/billing-details`;
export const URL_CALL_SETTING_GET = `${BASE_URL}accounts/call-setting`;
export const URL_CALL_SETTING_POST = `${BASE_URL}accounts/call-setting`;
export const URL_ACCOUNTS_CARD = `${BASE_URL}accounts/card`;
export const URL_CANCEL_SUBSCRIPTION = `${BASE_URL}accounts/cancel-subscription`;
export const URL_CHANGE_CARD = `${BASE_URL}accounts/change-card`;
export const URL_CONFIRM_PAYMENT = `${BASE_URL}accounts/confirm-payment/{id}`;
export const URL_FEEDBACK = `${BASE_URL}accounts/feedback`;
export const URL_RECHARGE_ACCOUNT = `${BASE_URL}accounts/recharge-account`;
export const URL_SUBSCRIBE = `${BASE_URL}accounts/subscribe`;
export const URL_RECHARGE_PLAN = `${BASE_URL}recharge-plan`;
export const URL_RECHARGE_PLAN_GET = `${BASE_URL}recharge-plan{id}`;
export const URL_STRIPE_PRODUCT = `${BASE_URL}stripe-product{id}`;
export const URL_FOLLOW_PROFILE = `${BASE_URL}room/follow-profile/{id}`;
export const URL_NEARBY_ROOMS = `${BASE_URL}room/nearby-rooms`;
export const URL_REMOVE_GUEST = `${BASE_URL}room/remove-guest`;
export const URL_ROOM_DETAILS = `${BASE_URL}room/room-details/{id}`;
export const URL_SET_LOGGED_TIME = `${BASE_URL}room/set-logged-time`;

////////////////////////////////////////////////////////////////////////////
export const URL_RESEND_REGISTRATION_CODE = `${BASE_URL}registration/resend/`;
export const URL_CONFIRM_REGISTRATION = `${BASE_URL}registration/confirm/`;
export const URL_GET_USER = `${BASE_URL}accounts/`;

export const IMAGE_URL = 'https://api.socialplay.com';
