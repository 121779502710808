import { connect } from 'react-redux';
import PublicProfile from 'views/profile/publicProfile/PublicProfile';
import { getUser, publicProfile } from 'store/selector/auth.selector';

const mapStateToProps = (state: any) => {
  const user = getUser(state);
  const profile = publicProfile(state);
  return { user, profile };
};
export default connect(mapStateToProps, null)(PublicProfile);
