import React, {FC, useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from 'components/header/Header.container';
import Footer from 'components/footer/Footer';
import { User } from 'modules/user/types';
import Styles from 'views/chatScreen/ChatScreen.module.scss';
import backToDashboardImage from 'assets/svg/back-to-dashboard.svg';
import ChatSideBar from 'components/chatSideBar/ChatSideBar';
import ChatContent from 'components/chatContent/ChatContent';
import MessageThread from 'components/chatContent/messageThread/MessageThread';
import ChatInfo from 'components/chatContent/chatInfo/ChatInfo';
import { createRoom, useGetMessages } from 'store/services/firebase-broadcast';
// import { getCurrentDate } from 'helpers/utils';
// import { ref, onValue } from 'firebase/database';
import { db, storage, reference, getDownloadURL, listAll } from 'utils/firebase-config';


interface StartScreenProps {
  user: User;
}

const ChatScreen: FC<StartScreenProps> = (props: StartScreenProps) => {
  const [showReplies, setShowReplies] = useState(false);
  const [chatInfo, setChatInfo] = useState(false);
  const [showThreads, setShowThreads] = useState<any>({});
  const location: any = useLocation();
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [selected, setSelected] = useState<any>(location.state.selected);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [chatContent, setChatContent] = useState<any>([]);
  const [key, setKey] = useState(0);
  const [files, setFiles] = useState<any>([]);
  const { pk }: any = selected;

  const handleThreads = (e: any) => {
    setShowThreads(e);
  };

  const chat = useGetMessages(key);

  useEffect(() => {
    if (selected.participants.length > 0) {
      selected.participants.map((i: any) => {
        if (i.pk === user.pk || selected.host.pk === user.pk) {
          setIsJoined(true);
          return '';
        }
        return '';
      });
    } else {
      if (selected.host.pk === user.pk) {
        setIsJoined(true);
      } else {
        setIsJoined(false);
      }
    }
  }, [selected]);

  useEffect(() => {
    if (pk !== undefined ) {
      setKey(pk);
    }
  }, [pk]);

  useEffect(() => {
    setChatContent(chat);
    const { id } = showThreads;
      chat.filter((i: any) => {
        if (i.id === id) {
          setShowThreads(i);
        }
      })
  }, [chat]);

  // useEffect(() => {
  //   if (Object.keys(selected).length > 0 ) {
  //     onValue(ref(db, `rooms/${selected.pk}`), (querySnapshot: any) => {
  //       setRoom(querySnapshot.val());
  //     });
  //   }
  // }, [selected]);

  // useEffect(() => {
  //   if (room === null) {
  //     const room = {
  //       roomId: selected.pk,
  //       topic: selected.topic,
  //       host: selected.host,
  //       created_at: getCurrentDate(),
  //     };
  //     createRoom(room);
  //   }
  // }, [room]);

  useEffect(() => {}, [chatContent]);

  useEffect(() => {
    setChatInfo(false);
    setShowReplies(false);
    const listRef = reference(storage, `/${selected.pk}`);
    selected.attachments = [];
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        res.items.forEach(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          setFiles([url]);
          selected.attachments.push(url);
        });
      }).catch((error) => {
        console.log('Error : ', error);
    });
  }, [selected]);
  return (
    <>
      <Header />
      <div className="custom-container">
        <section className={Styles.bodyContent}>
          <div className={Styles.startScreenWrapper}>
            <ChatSideBar conversations={location.state.conversations} setSelected={setSelected} />
            <section className={`${Styles.chatSection} ${!isJoined && Styles.notJoinedWrapper} ${showReplies || chatInfo ? Styles.threadSection : ''} `}>
              {!isJoined ? (
                <div className={Styles.joinRoom}>
                  <p>Join a room to start chatting</p>
                </div>
              ) : (
                <ChatContent
                  key={Date.now()}
                  replyItems={(e: any) => handleThreads(e)}
                  viewReplies={() => {
                    setShowReplies(true);
                    setChatInfo(false);
                  }}
                  chatInfo={() => {
                    setChatInfo(!chatInfo);
                    setShowReplies(false);
                  }}
                  chats={chatContent}
                  selected={selected}
                  actions={showReplies}
                />
              )}
            </section>
            {chatInfo && <ChatInfo info={selected} />}
            {showReplies && <MessageThread actions={showReplies} selected={selected} item={showThreads} hideReplies={(e: any) => setShowReplies(e)} />}
          </div>
        </section>
      </div>
      <Footer />
      <div className={Styles.backToDashboard}>
        <Link to="/conversations-nearby">
          <img src={backToDashboardImage} alt="back to dashboard" />
        </Link>
      </div>
    </>
  );
};
export default ChatScreen;
