import React from 'react';
import { Link } from 'react-router-dom';
import splashScreenImage from 'assets/images/splashScreenTriangle.png';
import socialPlayImg from 'assets/images/social-play-logo-vertical.png';
import playIcon from 'assets/images/play-icon.png';
import dropdownIcon from 'assets/images/dropdown-white.png';
import Styles from 'views/splashScreen/SplashScreen.module.scss';

const SplashScreen = () => {
  return (
    <section className={Styles.wrapper}>
      <img src={splashScreenImage} alt="background img" className={Styles.bgImage} />
      <div className={Styles.innerSection}>
        <div className={Styles.detailsWrapper}>
          <img src={socialPlayImg} alt="social play logo vertical" className={Styles.socialLogo} />
          <p className={`poppins-bold text-black ${Styles.description}`}>Video Chat with people nearby.</p>
          <p className={`poppins-bold text-black ${Styles.description}`}>Press play on your social life.</p>
          <img src={playIcon} alt="play icon" className={Styles.playIcon} />
          <div className={`${Styles.copyRightText} text-black`}>© 2022 All Rights Reserved.</div>
        </div>
      </div>
      <footer className={Styles.footerSection}>
        <Link to="/about">
          <img src={dropdownIcon} alt="angle down" />
        </Link>
      </footer>
    </section>
  );
};

export default SplashScreen;
