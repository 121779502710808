import React, {useState} from 'react';
import { Link } from 'react-router-dom'
// import socialPlayImg from 'assets/images/social-play-logo-vertical.png';
// import playIcon from 'assets/images/play-icon.png';
import upArrowIcon from 'assets/images/up-arrow-icon.png';
import laptopHomeImage from 'assets/images/laptop-home.png';
import Styles from 'views/about/About.module.scss';
import SliderWrapper from "components/controls/Slider/SliderWrapper";
import {AboutPageSlider} from "components/controls/Slider/SliderResponsiveness";

const About = () => {
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        }else if(scrolled <= 300) {
            setVisible(false);
        }
    };
  const scrollToTop = () => {
      window.scroll({top: 0, behavior: 'smooth'});
  };
    window.addEventListener('scroll', toggleVisible);
  return (
    <section className={Styles.wrapper}>
        <div className={Styles.topHeader}>
            <Link to="/Login">Login</Link>
        </div>
        <div className="container">
            <p className={Styles.feature}>FEATURES</p>
            <p className={Styles.heading}>How does <span className={Styles.headingSpan}>Social Play</span> work?</p>
            <div className={Styles.videoSection}>
                <iframe
                    width="908"
                    height="510"
                    src="https://www.youtube.com/embed/JQrPpj7b0_g"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                >
                </iframe>
            </div>
        </div>
        <div className={Styles.carouselWrapper}>
            <div className="container">
                <SliderWrapper slideToShow={1} sliderSetting={AboutPageSlider}>
                    <div className={Styles.sliderItem}>
                        <img src={laptopHomeImage} className="w-100" alt="laptopImage"/>
                    </div>
                </SliderWrapper>
                <div className="mt-30">
                    <h5 className={`m-0 font-weight-600 text-black text-center ${Styles.sliderItemCaption}`}>Video chat with people nearby. View the <br />
                        conversation topics and join in.</h5>
                </div>
            </div>
        </div>
        <div className="container">
            <div className={Styles.learnMoreWrapper}>
                <p className={Styles.learnMore}>LEARN MORE</p>
                <p className={Styles.heading}>About <span className={Styles.headingSpan}>Social Play</span></p>
                <p className={Styles.paragraph}>
                    {`Social Play > is an app made by Open build Co, the first customer owned company. As a customer using Social Play > you can invest. Follow this `}
                    <a className={Styles.link} href="">link </a>
                    to our crowd funding campaign.
                </p>
                <p className={`mt-3 ${Styles.paragraph}`}>
                    {`Also check out another app we made called SoundBird. With this app you can use your phone as a microphone and the phone’s of your audience as speakers. Check it out at `}
                    <a className={Styles.link} href="">soundbirdapp.com</a>
                </p>
            </div>
        </div>
        <div className={Styles.backToTopIcon} onClick={scrollToTop} style={{display: visible ? 'block' : 'none'}}>
            <img src={upArrowIcon} alt="play icon" className={Styles.playIcon} />
            BACK TO TOP
        </div>
        <footer className={Styles.footerSection}>
            <div className={`${Styles.copyRightText} text-black fontFourteen`}>© 2022. All Rights Reserved.</div>
        </footer>
    </section>
  );
};

export default About;
