import MenuLanding from 'views/profile/landing';
import PublicProfile from 'views/profile/publicProfile';
import ConversationsNearby from 'views/conversationsNearby/index';
import ChatScreen from 'views/chatScreen/ChatScreen';
import BroadCast from 'views/BroadCast/index';
import Jitsi from '../components/Jitsi/Jitsi';

export default [
  {
    path: '/profile/edit',
    component: MenuLanding,
  },
  {
    path: '/invite-friend',
    component: MenuLanding,
  },
  {
    path: '/currency',
    component: MenuLanding,
  },
  {
    path: '/date-and-time',
    component: MenuLanding,
  },
  {
    path: '/notifications',
    component: MenuLanding,
  },
  {
    path: '/terms-and-conditions',
    component: MenuLanding,
  },
  {
    path: '/privacy-policy',
    component: MenuLanding,
  },
  {
    path: '/explainer-video',
    component: MenuLanding,
  },
  {
    path: '/subscription',
    component: MenuLanding,
  },
  {
    path: '/feedback',
    component: MenuLanding,
  },
  {
    path: '/call',
    component: BroadCast,
  },
  {
    path: '/jitsi',
    component: Jitsi,
  },
  {
    path: '/profile',
    component: PublicProfile,
  },
  {
    path: '/chat-room',
    component: ChatScreen,
  },
  {
    path: '/conversations-nearby',
    component: ConversationsNearby,
  },
];
