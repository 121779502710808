import React, {FunctionComponent, useEffect, useState} from 'react';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import InputField from 'components/textField/InputField';
import Button from 'components/button/Button';
import Styles from './EmailVerification.module.scss';
import { useHistory } from 'react-router';
import { validateEmail } from 'helpers/utils';

interface EmailVerificationProps {
  verifyEmail: Function;
  isEmailVerified: boolean;
  isLoading: boolean;
  error: string;
}
const EmailVerification: FunctionComponent<EmailVerificationProps> = (props: EmailVerificationProps) => {
  const { verifyEmail, isEmailVerified, isLoading, error } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isVerified] = useState(isEmailVerified);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();

  const handleValidateEmail = () => {
    if (JSON.parse(localStorage.getItem('userObj') || '').email !== email) {
      setEmailError(true);
      return;
    } else {
      if (validateEmail(email)) {
        setEmailError(false);
        if (!isVerified) {
          verifyEmail({
            email
          });
        }
      } else {
        setEmailError(true);
      }
    }
  }

  useEffect(() => {
    if (isEmailVerified) {
      history.push('/registration/otp');
    }
  }, [isEmailVerified]);

  useEffect(() => {}, [error]);

  useEffect(() => {
    if (email.length === 0) {
     setEmailError(false);
    }
  }, [email]);

  useEffect(() => {
    if (email === null) history.push('/registration');
    if (email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);

  useEffect(() => {
    setEmail(JSON.parse(localStorage.getItem('userObj') as string).email)
  }, []);
  return (
    <section className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h5 className="fontTwenty font-weight-500 text-black text-uppercase">Step 2</h5>
          <p className={`mb-0 ${Styles.profileTitle}`}>Confirm your email address</p>
          <p className={Styles.tagLine}>
            To proceed in signing up, please confirm <br /> your email address first.
          </p>
          <div className="form-group">
            <InputField
              type="email"
              placeholder="Confirm email address"
              cssClass="bg-grey"
              value={email}
              handleChange={(e: string) => setEmail(e)}
            />
          </div>
          {emailError && <p className={Styles.error}>Invalid Email</p>}
          {error && <p className={`mt-2 ${Styles.error}`}>{error}</p>}
          <Button clickHandler={handleValidateEmail} cssClass={Styles.confirmEmailBtn} showLoading={isLoading} label="Confirm" disabled={disabled} />
        </div>
      </div>
    </section>
  );
};
export default EmailVerification;
