import { fromJS } from 'immutable';
import ACTIONS from 'store/constants';

const initialState: any = fromJS({
  loading: false,
  invitation: '',
  notificationSetting: false,
  updateNotification: '',
  dateTimeSetting: '',
  updateDateTime: '',
  feedback: '',
});

export default function generalReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.INVITE_PEOPLE.PENDING: {
      return state.set('loading', true).set('error', null);
    }
    case ACTIONS.INVITE_PEOPLE.SUCCESS: {
      return state.set('loading', false).set('error', '').set('invitation', action.data.message);
    }
    case ACTIONS.INVITE_PEOPLE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }

    case ACTIONS.FEED_BACK.PENDING: {
      return state.set('loading', true).set('error', null);
    }
    case ACTIONS.FEED_BACK.SUCCESS: {
      return state.set('loading', false).set('error', '').set('feedback', action.data.message);
    }
    case ACTIONS.FEED_BACK.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }

    case ACTIONS.NOTIFICATION_SETTING.PENDING: {
      return state.set('loading', true).set('error', null);
    }
    case ACTIONS.NOTIFICATION_SETTING.SUCCESS: {
      return state.set('loading', false).set('error', '').set('notificationSetting', action.data.notification);
    }
    case ACTIONS.NOTIFICATION_SETTING.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }

    case ACTIONS.UPDATE_NOTIFICATION_SETTING.PENDING: {
      return state.set('loading', true).set('error', null);
    }
    case ACTIONS.UPDATE_NOTIFICATION_SETTING.SUCCESS: {
      return state.set('loading', false).set('error', '').set('updateNotification', action.data.message);
    }
    case ACTIONS.UPDATE_NOTIFICATION_SETTING.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }

    case ACTIONS.DATE_TIME_SETTING.PENDING: {
      return state.set('loading', true).set('error', null);
    }
    case ACTIONS.DATE_TIME_SETTING.SUCCESS: {
      return state.set('loading', false).set('error', '').set('dateTimeSetting', action.data);
    }
    case ACTIONS.DATE_TIME_SETTING.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }

    case ACTIONS.UPDATE_DATE_TIME_SETTING.PENDING: {
      return state.set('loading', true).set('error', null);
    }
    case ACTIONS.UPDATE_DATE_TIME_SETTING.SUCCESS: {
      return state.set('loading', false).set('error', '').set('updateDateTime', action.data.message);
    }
    case ACTIONS.UPDATE_DATE_TIME_SETTING.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }

    default: {
      return state;
    }
  }
}
