import React, { FunctionComponent, useState } from 'react';
import Button from 'components/button/Button';
import superHostIcon from 'assets/svg/superhost-subscription.svg';
import Styles from 'views/subscription/Subscription.module.scss';
import CustomModal from '../../components/customModal/CustomModal';

const Subscription: FunctionComponent = () => {
  const [showSubscribe, setShowSubscribe] = useState(false);
  const subscription = () => {
    document.body.classList.add('modal-open');
    return (
      <div className="text-center">
        <p className="fontSixteen font-weight-normal text-black my-4">
          Proceed to payment to complete your
          <br />
          transaction.
        </p>
        <Button label="PROCEED TO PAYMENT MERCHANT" clickHandler={handlePaymentMerchant} />
        <div
          className="fontSixteen text-blue font-weight-600 text-uppercase mt-3"
          onClick={closeDialog}
          onKeyPress={closeDialog}
          role="button"
          tabIndex={0}
        >
          Close
        </div>
      </div>
    );
  };
  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setShowSubscribe(false);
  };
  const handlePaymentMerchant = () => {
    console.log('handle payment merchant');
  };
  return (
    <>
      <div className="pb-5">
        <div className="borderStyle text-center">
          <img src={superHostIcon} className={Styles.superHostImage} alt="super host icon" />
          <h2 className="text-black poppins-bold my-4">Subscribe to be a Super Host!</h2>
          <p className="text-black fontTwenty font-weight-normal mb-0">
            Subscribing to be a Super Host will get you a Master Key.
          </p>
          <ul className={`list-unstyled ${Styles.listingWrapper}`}>
            <li>Waves all fees for guests joining your rooms.</li>
            <li>Join any room for free with host approval.</li>
            <li>Create group chats for your room.</li>
          </ul>
          <Button cssClass="mt-4" label="SUBSCRIBE FOR $10 A MONTH" clickHandler={() => setShowSubscribe(true)} />
        </div>
      </div>
      {showSubscribe && (
        <CustomModal
          title="You’re getting a Super Host Subscription. Nice!"
          wrapperClass={Styles.subscriptionWrapper}
          closeDialog={closeDialog}
        >
          {subscription()}
        </CustomModal>
      )}
    </>
  );
};

export default Subscription;
