import { connect } from 'react-redux';
import { getLoading, roomCreated } from 'store/selector/conversation.selector';
import { getUser} from 'store/selector/auth.selector';
import { createRoomRequest } from 'store/actions/conversation.actions';
import Header from 'components/header/Header';

const mapDispatchToProps = { createRoomRequest };

const mapStateToProps = (state: any) => {
  const isLoading = getLoading(state);
  const status = roomCreated(state);
  return { isLoading, status };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
