import React, { FC, useState } from 'react';
import goldIcon from 'assets/svg/gold-piece.svg';
import InputField from 'components/textField/InputField';
import Button from 'components/button/Button';
import Styles from 'views/inviteFriend/InviteFriend.module.scss';
import { validateEmail, validatePhone } from 'helpers/utils';

interface InviteFriendProps {
  cssClass?: string;
  invitePeople: Function;
  loading: boolean;
  invitation: string;
  isPopup?: boolean;
  closePopup?: (event: React.FormEvent<HTMLDivElement>) => void;
}

const InviteFriend: FC<InviteFriendProps> = (props: InviteFriendProps) => {
  const { cssClass, invitePeople, loading, invitation, isPopup, closePopup } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhone] = useState('');
  const handleInvite = () => {
    if (email) {
      if (validateEmail(email)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (phone) {
      if (validatePhone(phone)) {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }
    if (!emailError && !phoneError){
      setEmail('');
      setPhone('');
      email &&
      invitePeople({
        email,
      });
      phone &&
      invitePeople({
        mobile_number: phone,
      });

    }
  };
  return (
    <div className="pb-5">
      <div className={`borderStyle ${cssClass}`}>
        <div className={Styles.inviteFriend}>
          <img src={goldIcon} alt="gold icon" />
          <h3>
            Invite a friend! If they accept, we’ll deposit a Gold
            <br />
            Piece into your account.
          </h3>
          <p>You can send the invite via text or email.</p>
        </div>
        <div className={Styles.formWrapper}>
          <div className="form-group mt-30">
            <InputField placeholder="Enter phone number" type="text" handleChange={setPhone} />
            {phoneError && <p style={{color: 'red'}}>Invalid Phone</p>}
          </div>
          <div>
            <h6>
              <span>or</span>
            </h6>
          </div>
          <div className="form-group">
            <InputField placeholder="Enter Email" type="email" handleChange={setEmail} />
            {emailError && <p style={{color: 'red'}}>Invalid Email</p>}
            {invitation && <p style={{color: 'green'}}>{invitation}</p>}
          </div>
          <Button label="SEND INVITE" cssClass="mt-4" showLoading={loading} clickHandler={handleInvite} />
          {isPopup && <div onClick={closePopup} onKeyUp={closePopup} role="button" tabIndex={0} className={Styles.close}>Close</div>}
        </div>
      </div>
    </div>
  );
};
export default InviteFriend;
