import React, { FunctionComponent, useEffect, useState } from 'react';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import Button from 'components/button/Button';
import CheckBox from 'components/checkBox/CheckBox';
import Styles from './StepFour.module.scss';
import { useHistory } from 'react-router';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

interface StepFourProps {
  loginRequest: Function;
}
const StepFour: FunctionComponent<StepFourProps> = (props: StepFourProps) => {
  const { loginRequest } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const handleDone = () => {
    const user = getItemFromLocalStorage('userObj');
    if (user !== null) {
      loginRequest({
        email,
        password,
      });
    }
    if(user) {
      history.push('/conversations-nearby');
    } else {
      history.go(0);
    }
  };
  useEffect(() => {
    if (checked) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [checked]);

  useEffect(() => {
    setEmail(JSON.parse(localStorage.getItem('userObj') as string).email)
    setPassword(JSON.parse(localStorage.getItem('userObj') as string).password)
  }, []);
  return (
    <section className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h5 className="fontTwenty font-weight-500 text-black text-uppercase">Step 4</h5>
          <p className={`mb-40 ${Styles.profileTitle}`}>
            Agree to Social Play&nbsp;
            {'>'}
            &nbsp;guidelines
          </p>
          <p>
            Allow Social Play&nbsp;
            {'>'}
            &nbsp;to use your location. The app will use your approximate location to show conversation rooms closest
            to you. Never disclose your home address and when you make new friends meet in a public place.
          </p>
          <p className="mt-30 fontSixteen mb-0">
            To use this site you must be at least 18 years old. The general conversation guidelines are PG-13. Nudity
            and adult content is prohibited. Social Play &nbsp;
            {'>'}
            &nbsp; is non-political by nature. This is not a place to promote fascist agendas or organize acts against
            governments. Crazy conspiracy theories are also faux pas on this site. The culture of Social Play &nbsp;
            {'>'}
            &nbsp; is that of a normal person. It’s welcoming and inclusive to everyone.
            <br />
            <span className="font-weight-600">Just have fun and join the conversation!</span>
          </p>
          <div className="mt-40 ">
            <CheckBox id="abc" label="I Agree to Social Play > guidelines" handleChange={handleChange} />
          </div>
          <Button clickHandler={handleDone} cssClass={Styles.confirmEmailBtn} label="Done" disabled={disabled} />
        </div>
      </div>
    </section>
  );
};
export default StepFour;
