import {
  IEmailVerificationParams,
  IOTPVerificationParams,
  ILoginParams,
  IPublicProfileParams,
  IRegisterParams,
  IProfileParams,
} from 'modules/params/param.type';
import { User } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/constants';

export const loginAttempt = {
  pending: {
    type: ACTIONS.LOGIN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.LOGIN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.LOGIN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const verifyEmailRequest = (state: IEmailVerificationParams) => ({
  type: ACTIONS.VERIFY_EMAIL_CALL,
  payload: state,
});

export const verifyEmail = {
  pending: (data: any) => ({
    type: ACTIONS.VERIFY_EMAIL.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: any) => ({
    type: ACTIONS.VERIFY_EMAIL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.VERIFY_EMAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const verifyOTPRequest = (state: IOTPVerificationParams) => ({
  type: ACTIONS.VERIFY_OTP_CALL,
  payload: state,
});

export const verifyOTP = {
  pending: (data: any) => ({
    type: ACTIONS.VERIFY_OTP.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: any) => ({
    type: ACTIONS.VERIFY_OTP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.VERIFY_OTP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const logoutSucceed = () => ({
  type: ACTIONS.AUTH_LOGOUT,
});

export const callLogout = () => ({
  type: ACTIONS.AUTH_LOGOUT_BEGIN,
});

export const authenticatedUser = (state: User) => ({
  type: ACTIONS.AUTHENTICATED_USER,
  data: state,
});

export const getLoginRequest = (state: ILoginParams) => ({
  type: ACTIONS.LOGIN_CALL,
  payload: state,
});

export const userRegistrationBegin = (state: IRegisterParams) => ({
  type: ACTIONS.SIGNUP_CALL,
  payload: state,
});

export const registerUser = {
  pending: {
    type: ACTIONS.SIGNUP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.SIGNUP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SIGNUP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateProfileRequest = (state: IProfileParams) => ({
  type: ACTIONS.UPDATE_PROFILE_BEGIN,
  payload: state,
});

export const updateProfile = {
  pending: (data: any) => ({
    type: ACTIONS.UPDATE_PROFILE.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: any) => ({
    type: ACTIONS.UPDATE_PROFILE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deleteProfileRequest = () => ({
  type: ACTIONS.DELETE_PROFILE_BEGIN,
});

export const deleteProfile = {
  pending: {
    type: ACTIONS.DELETE_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.DELETE_PROFILE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DELETE_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createPublicProfileRequest = (payload: IPublicProfileParams) => ({
  type: ACTIONS.CREATE_PUBLIC_PROFILE_BEGIN,
  payload,
});

export const createPublicProfile = {
  pending: {
    type: ACTIONS.CREATE_PUBLIC_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CREATE_PUBLIC_PROFILE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_PUBLIC_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};