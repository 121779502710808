import React, { FC, useState } from 'react';
import Header from 'components/header/index';
import { User } from 'modules/user/types';
import userImage from 'assets/images/image1.png';
import distanceIcon from 'assets/svg/distance.svg';
import joinIcon from 'assets/svg/join button.svg';
import userIcon from 'assets/svg/user_orange.svg';
import superHostKey from 'assets/svg/superhost-user-key-indicator.svg';
import Styles from 'views/profile/publicProfile/PublicProfile.module.scss';
import CardItem from 'views/profile/publicProfile/card/item';
import {IMAGE_URL} from 'store/services/URL';

interface PublicProfileProps {
  user: User;
  profile: any;
  test: string;
}

const PublicProfile: FC<PublicProfileProps> = (props: PublicProfileProps) => {
  const { profile } = props;
  const [showToolTip, setShowToolTip] = useState(false);
  const [followToggle, setFollowToggle] = useState(false);
  const profileCardDetails = [
    {
      title: 'Number of Rooms Started',
      description: '',
      total: '25',
    },
    {
      title: 'Number of Hours Logged',
      description: '',
      total: '100hrs',
    },
    {
      title: 'Evictions',
      description: 'Number of times they have been kicked out of a room',
      total: '0',
    },
  ];
  const user = JSON.parse(localStorage.getItem('user') || '');
  return (
    <>
      <Header />
      <section className="custom-container">
        <div className={Styles.profileBox}>
          <div className={Styles.profileBoxInner}>
            <div className={Styles.profileImageWrapper}>
              <img src={`${IMAGE_URL}${user.profile}`} loading="lazy" className={Styles.profileImage} alt="profile img" />
              {user.is_super_host && <img src={superHostKey} loading="lazy" alt="is super host" className={Styles.superHostKey} />}
            </div>
            <div>
              <h3>{user.name}</h3>
              <p>ID NUMBER: {user.pk}</p>
            </div>
          </div>
          <div className="position-relative">
            <button
              type="button"
              className={Styles.followBtn}
              onClick={() => setFollowToggle(!followToggle)}
              onKeyUp={() => setFollowToggle(!followToggle)}
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
            >
              {followToggle ? 'Unfollow' : 'Follow'}
            </button>
            {showToolTip && (
              <div className={Styles.followToolTip}>
                <p className={`mb-0 ${Styles.toolTip}`}>
                  Follow to receive notifications when this host starts a room.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={Styles.cardWrapper}>
          {profileCardDetails.map((item) => (
            <CardItem title={item.title} description={item.description} total={item.total} />
          ))}
        </div>
        <hr />
        <div className={Styles.openCardWrapper}>
          <h4>Currently Open Room</h4>
          <div className={Styles.openRoomCard}>
            <div className={Styles.openCardInner}>
              <div>
                <div className="d-flex justify-content-start align-items-center">
                  <h5 className="mb-0 text-black font-weight-500 text-uppercase">TOPIC:</h5>
                  <div className={Styles.topicBtn}>Hiking</div>
                </div>
                <div
                  className={`d-flex justify-content-start align-items-center column-gap-20 mt-4 
                  ${Styles.distanceWrapper}`}
                >
                  <div>
                    <img src={distanceIcon} alt="distance" />
                    <span>1 KM</span>
                  </div>
                  <div>
                    <img src={userIcon} alt="distance" />
                    <span>21 people are in this room</span>
                  </div>
                </div>
              </div>
              <div className={Styles.joinBtnWrapper}>
                <img src={joinIcon} alt="join btn" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PublicProfile;
