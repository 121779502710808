import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import Button from 'components/button/Button';
import cameraImage from 'assets/images/camera-grey.png';
import userImage from 'assets/images/image1.png';
import CustomModal from 'components/customModal/CustomModal';
import Styles from './UploadProfile.module.scss';

interface UploadProfileProps {
  userRegistrationRequest: Function;
}
const UploadProfile: FunctionComponent<UploadProfileProps> = (props: UploadProfileProps) => {
  const { userRegistrationRequest } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState<any>('');
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState(false);
  const user = JSON.parse(localStorage.getItem('userObj') || '');
  const history = useHistory();
  const changeImage = (e: any) => {
    const fileType = e.target.files[0].type;
    const type = fileType.split("/");
    if (type[0] === 'image') {
      setAlert(false);
      setSelectedImage(e.target.files[0]);
    } else {
      setSelectedImage(null);
      setAlert(true);
    }
  };
  const showGuideLine = () => {
    document.body.classList.add('modal-open');
    setIsOpen(true);
  };
  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setIsOpen(false);
  };
  const handleConfirm = () => {
    document.body.classList.remove('modal-open');
    setIsOpen(false);
  };
  useEffect(() => {
    if (selectedImage) {
      setImage(selectedImage);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    }, [selectedImage]);

  const handleNext = () => {
    let data = new FormData();
    data.append('name', user.name);
    data.append('email', user.email);
    data.append('password', user.password);
    data.append('profile_pic', image);
    data.append('token', user.token);
    userRegistrationRequest(data);
    history.push('/registration/step-four');
  };

  const modalContent = () => (
    <>
      <p className="text-center text-black fontSixteen font-weight-normal">
        Take a picture that clearly shows your face and is well lit. Natural light greatly improves profile pics. The
        picture must be of you and not someone else. Your profile will be shown to the host when making a request to
        join a room.
      </p>
      <div className={`${Styles.userImagesWrapper}`}>
        <img src={userImage} alt="user img" />
        <img src={userImage} alt="user img" />
        <img src={userImage} alt="user img" />
        <img src={userImage} alt="user img" />
        <img src={userImage} alt="user img" />
        <img src={userImage} alt="user img" />
      </div>
      <div className="text-center">
        <Button label="GOT IT!" clickHandler={handleConfirm} cssClass={Styles.gotButtonWidth} />
      </div>
    </>
  );
  return (
    <section className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h5 className="fontTwenty font-weight-500 text-black text-uppercase">Step 3</h5>
          <p className={`mb-0 ${Styles.profileTitle}`}>Take a selfie for your profile picture</p>
          <p className={Styles.tagLine}>
            Take a picture using the app. Selfies are required but don’t sweat it. You can change it later.
          </p>
          <div className="cursor-pointer" onClick={showGuideLine} onKeyPress={showGuideLine} role="button" tabIndex={0}>
            <p className="fontFifteen text-blue font-weight-bold text-decoration-none">
              SEE OUR SELFIE PROFILE PICTURE GUIDELINES
              <i className="fas fa-long-arrow-alt-right ml-2" />
            </p>
          </div>
          <div className={Styles.uploadFileWrapper}>
            <div className={Styles.uploadFileInner}>
              {selectedImage ? (
                <img src={URL.createObjectURL(selectedImage)} alt="upload file" className={Styles.uploadImage} />
              ) : (
                <img src={cameraImage} alt="camera img" className={Styles.cameraImage} />
              )}
              <input type="file" accept="image/*" onChange={(e) => changeImage(e)} />
            </div>
            <p className="font-weight-600 fontFourteen text-blue mb-0">
              <i className="fas fa-camera mr-2" />
              TAKE A PICTURE
            </p>
          </div>
          {alert && <p style={{color: 'red'}}>Only Accept Image (.jpg, .jpeg, .png) etc</p>}
          <div className={`text-center w-100 ${Styles.signUpMarginBLock}`}>
            <Button cssClass={Styles.nextButton} iconClass="fas fa-long-arrow-alt-right ml-2" icon clickHandler={handleNext} label="Next" disabled={disabled} />
          </div>
        </div>
      </div>
      {isOpen && (
        <CustomModal
          closeDialog={closeDialog}
          wrapperClass={Styles.modalWrapperClass}
          title="Selfie Profile Picture Guidelines"
        >
          {modalContent()}
        </CustomModal>
      )}
    </section>
  );
};
export default UploadProfile;
