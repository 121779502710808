import React, { FunctionComponent } from 'react';
import Styles from "../PublicProfile.module.scss";

interface CardItemProps {
  title: string;
  description: string;
  total: string;
}

const CardItem: FunctionComponent<CardItemProps> = (props: CardItemProps) => {
  const { title, description, total } = props;
  return (
    <>
      <div className={Styles.cardInner}>
        <div className={Styles.cardDetails}>
          <h5>{title}</h5>
          {description && <p className="fontSixteen">{description}</p>}
        </div>
        <h3>{total}</h3>
      </div>
    </>
  );
};

export default CardItem;