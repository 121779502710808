import React, { FC, useState } from 'react';
import Styles from 'components/chatSideBar/ChatSideBar.module.scss';
import ChatParticipants from './chatParticipants/ChatParticipants';

interface ChatSideBarProps {
  cssClass?: string;
  conversations: any;
  setSelected: Function;
}

const ChatSideBar: FC<ChatSideBarProps> = (props: ChatSideBarProps) => {
  const { cssClass, conversations, setSelected } = props;
  const [selectedChat, setSelectedChat] = useState(-1);
  const handleChangeChat = (i: any) => {
    setSelectedChat(i.pk);
    setSelected(i);
  };
  return (
    <aside>
      <div className={Styles.nearbyRooms}>
        <h6 className="m-0 font-weight-600">Nearby Rooms</h6>
      </div>
      <div className={Styles.nearbyRoomsListing}>
        <p className={`${Styles.status} ${cssClass} ${Styles.success} text-uppercase mb-0`}>Active</p>
        <ul className={`${Styles.activeListing} customScrollBar list-unstyled`}>
          {conversations
            .map((i: any, index: number) => (
              <ChatParticipants
                item={i}
                key={index}
                handleChangeIndex={handleChangeChat}
                itemIndex={i.pk === selectedChat}
                isSuperHost={i.host.is_super_host}
                id={i.pk}
              />
            ))}
        </ul>
        <p className={`${Styles.notActive} ${Styles.status} text-uppercase mb-0`}>Offline</p>
        <ul className={`${Styles.activeListing} customScrollBar list-unstyled mb-0`}>
          {/*{conversations*/}
          {/*  .map((i: any, index: number) => (*/}
          {/*    <ChatParticipants*/}
          {/*      item={i}*/}
          {/*      key={index}*/}
          {/*      handleChangeIndex={handleChangeChat}*/}
          {/*      itemIndex={i.pk === selectedChat}*/}
          {/*      isSuperHost={i.host.is_super_host}*/}
          {/*      id={i.pk}*/}
          {/*      offLineImageCss*/}
          {/*    />*/}
          {/*  ))}*/}
        </ul>
      </div>
    </aside>
  );
};
export default ChatSideBar;
