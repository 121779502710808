import React, { FC } from 'react';
import chatInfoImage from 'assets/svg/chat-info.svg';
import joinImage from 'assets/svg/join button.svg';
import Styles from 'components/chatContent/chatHeader/ChatHeader.module.scss';

interface ChatHeaderProps {
  cssClass?: string;
  name: string;
  chatInfo?: Function;
}

const ChatHeader: FC<ChatHeaderProps> = (props: ChatHeaderProps) => {
  const { cssClass, name, chatInfo } = props;
  const showChatInfo = () => {
    if (chatInfo) chatInfo();
  };
  return (
    <div className={`${cssClass} ${Styles.chatHeader}`}>
      <h4 className={`my-0 ${Styles.userName}`}>{name}</h4>
      <div className="d-flex align-items-center">
        <div onClick={showChatInfo} onKeyPress={showChatInfo} role="button" tabIndex={0}>
          <img src={chatInfoImage} alt="chat info" className={Styles.chatInfoImage} />
        </div>
        {/*<img src={joinImage} alt="chat info" className={Styles.chatInfoImage} />*/}
      </div>
    </div>
  );
};
export default ChatHeader;
