import { JitsiMeeting } from '@jitsi/web-sdk/lib/components';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { User } from 'modules/user/types';
import participantImg from 'assets/svg/user_white.svg';
import CustomModal from 'components/customModal/CustomModal';
import InviteFriend from 'views/inviteFriend';
import { useHistory } from 'react-router';
import { updateCallStatus, useGetCallStatus } from 'store/services/firebase-broadcast';
import Styles from 'components/Jitsi/Jitsi.module.scss';
import Button from 'components/button/Button';

type participant = { id: string; name: string };

interface JitsiProps {
  user: User;
  broadCast: any;
  handleClose: Function;
}

const Jitsi: FunctionComponent<JitsiProps> = (props: JitsiProps) => {
  const { user, broadCast, handleClose } = props;
  const room = broadCast;
  const apiRef = useRef<any>();
  const [host, setHost] = useState<any>({});
  const [name, setName] = useState('');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [confirmEndCall, setConfirmEndCall] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(true);
  const [logItems, updateLog] = useState<string[]>([]);
  const [noOfParticipants, setNoOfParticipants] = useState(0);
  const [knockingParticipants, updateKnockingParticipants] = useState<participant[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(room).length > 0) {
      const obj = {
        roomId: room.pk,
        status: true,
      }
      updateCallStatus(obj).then((e) => console.log(e));
    }
  }, [room])

  const status = useGetCallStatus(room.pk);

  useEffect(() => {
    if (status !== null && status.hasOwnProperty('call_status')) {
      if (status.call_status === false) handleClose();
    }
  }, [status]);
  
  const closeDialog = () => {
    setShowPopup(false);
    setConfirmEndCall(false);
  };

  const handleBack = () => {
    setConfirmEndCall(true);
  };

  const handleConfirmLeft = () => {
    setConfirmEndCall(false);
    handleReadyToClose();
  };

  useEffect(() => {
    setHost(room.host);
  }, [room]);

  useEffect(() => {
    if (host !== undefined) {
      setName(host.name);
    }
  }, [host]);

  useEffect(() => () => {
      if (apiRef && apiRef.current) {
        apiRef.current.dispose();
      }
    }, []);

  const endCallModal = () => {
    return (
      <div className={Styles.modalWrapper}>
        <h4 className={Styles.description}>Are you sure want to {host ? 'end' : 'left'} call ?</h4>
        <Button label="Confirm" clickHandler={handleConfirmLeft} />
        <div onClick={closeDialog} onKeyPress={closeDialog} tabIndex={0} role="button">
          <p className="text-uppercase text-decoration-none mt-2 d-inline-block text-blue font-weight-600 fontFourteen">
            Close
          </p>
        </div>
      </div>
    );
  };

  const printEventOutput = (payload: any) => {
    updateLog((items: string[]) => [...items, JSON.stringify(payload)]);
  };

  const handleAudioStatusChange = (payload: any, feature: any) => {
    if (payload.muted) {
      updateLog((items) => [...items, `${feature} off`]);
    } else {
      updateLog((items) => [...items, `${feature} on`]);
    }
  };

  const handleChatUpdates = (payload: any, ref: any) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    ref.current.executeCommand('toggleChat');
    updateLog((items: string[]) => [...items, `you have ${payload.unreadCount} unread messages`]);
  };

  const handleKnockingParticipant = (payload: participant) => {
    updateLog((items: any) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants: participant[]) => [...participants, payload]);
  };

  const handleParticipantLeft = (payload: participant) => {
    setNoOfParticipants(noOfParticipants - 1);
  };

  const handleParticipantJoined = (payload: participant) => {
    setNoOfParticipants(noOfParticipants + 1);
  };

  const resolveKnockingParticipants = async () => {
    const data: any = apiRef.current.getParticipantsInfo();
    // console.log('[resolveKnockingParticipants]', ref, condition);
    // knockingParticipants.forEach((p: participant) => {
    //   ref.current.executeCommand('answerKnockingParticipant', p?.id, condition(p));
    //   updateKnockingParticipants((participants) => participants.filter((item) => item.id === p.id));
    // });
  };

  const handleJitsiIFrameRef1 = (iframeRef: any) => {};

  const handleReadyToClose = () => {
    if (user.pk === host.pk) {
      const obj = {
        roomId: room.pk,
        status: false,
      }
      updateCallStatus(obj).then((e) => console.log(e));
      handleClose();
    } else {
      handleClose();
    }
  };

  const handleVideoConferenceJoined = (e: any) => {};

  const handleVideoConferenceLeft = (e: any) => {
    setShowChat(false);
    if (e.pk) {
      handleClose();
    }
  };

  const handleParticipantKickedOut = (e: any) => {
    console.log('Kick out.............', e);
    setShowChat(false);
  };

  const handleApiReady = (apiObj: any, ref: any) => {
    const reff: any = ref;
    reff.current = apiObj;

    reff.current.addEventListeners({
      // Listening to events from the external API
      audioMuteStatusChanged: (payload: any) => handleAudioStatusChange(payload, 'audio'),
      videoMuteStatusChanged: (payload: any) => handleAudioStatusChange(payload, 'video'),
      raiseHandUpdated: printEventOutput,
      tileViewChanged: printEventOutput,
      chatUpdated: (payload: any) => handleChatUpdates(payload, ref),
      knockingParticipant: handleKnockingParticipant,
      participantJoined: handleParticipantJoined,
      participantLeft: handleParticipantLeft,
      readyToClose: handleReadyToClose,
      participantKickedOut: handleParticipantKickedOut,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
    });
  };
  return (
    <div className="jitsiMeetingWrapper">
      {(
        <div className="d-flex align-items-center justify-content-between">
          <div className={`d-flex align-items-center`}>
            <i className={`pr-2 fas fa-chevron-left broadcastBackIcon`} onClick={handleBack} onKeyUp={handleBack} tabIndex={0} role="button" />
            <p className="mb-0 broadcastRoomName">{room.topic}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center" onClick={() => setShowPopup(true)} onKeyUp={() => setShowPopup(true)} tabIndex={0} role="button">
              <p className={`mb-0 broadCastInviteFriend`}>INVITE FRIENDS</p>
              <i className={`broadcastPlusIcon fa fa-plus`} />
            </div>
            <div className='line'>|</div>
            <div className="hostWrapper">
              <p className="mb-0 hostName">Host: {name}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <img src={participantImg} className="participantIcon" alt="" />
              <p className="mb-0 participants">{noOfParticipants}</p>
            </div>
          </div>
        </div>
      )}
      <JitsiMeeting
        domain="broadcast.socialplay.com"
        roomName={`${room.topic}?token=${user.access}&roomId=${room.pk}&userID=${user.pk}`}
        userInfo={{ displayName: user.name || '', email: `${user.email}_${user.pk}` || '' }}
        configOverwrite={{
          disableModeratorIndicator: true,
          prejoinPageEnabled: false,
          disableDeepLinking: true,
          disableKick: true,
        }}
        interfaceConfigOverwrite={{ SHOW_JITSI_WATERMARK: false }}
        onApiReady={(externalApi) => handleApiReady(externalApi, apiRef)}
        getIFrameRef={handleJitsiIFrameRef1}
      />
      {showPopup && (
        <CustomModal heightClass="inviteFriendModal">
          <InviteFriend cssClass="inviteFriendPopupWrapper" isPopup={showPopup} closePopup={closeDialog} />
        </CustomModal>
      )}
      {confirmEndCall && (
        <CustomModal heightClass="inviteFriendModal" closeDialog={closeDialog}>
          {endCallModal()}
        </CustomModal>
      )}
    </div>
  );
};

export default Jitsi;
