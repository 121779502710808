import { connect } from 'react-redux';
import InviteFriend from 'views/inviteFriend/InviteFriend';
import { invitePeopleRequest } from 'store/actions/general.actions';
import { getLoading, getInvitation } from 'store/selector/general.selector';

const mapDispatchToProps = { invitePeople: invitePeopleRequest };

const mapStateToProps = (state: any) => {
  const loading = getLoading(state);
  const invitation = getInvitation(state);
  return { loading, invitation };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriend);
