import { call, put, all, takeEvery } from 'redux-saga/effects';
import { authActions } from 'store/actions';
import api from 'store/services/auth.services';
import ACTIONS from 'store/constants';
import ApiResponse, {
  loginResponse,
  verifyEmailResponse,
  verifyOTPResponse,
  registrationResponse,
} from 'modules/response/response.types';
import {
  IEmailVerificationParams,
  IOTPVerificationParams,
  IGetUser,
  ILoginParams,
  IRegisterParams,
  IProfileParams,
  ICreatePublicProfileParams,
} from 'modules/params/param.type';
import { User } from '../../modules/user/types';
import getItemFromLocalStorage from '../../utils/getItemFromLocalStorage';

function* login(state: { type: string; payload: ILoginParams }) {
  try {
    yield put(authActions.loginAttempt.pending);
    const response: loginResponse = yield call(api.loginRequest, state.payload);
    if (response.access) {
      yield localStorage.setItem('accessToken', response.access);
      const userData: User = yield call(api.getProfile);
      userData.access = response.access;
      userData.refresh = response.refresh;
      yield localStorage.setItem('user', JSON.stringify(userData));
      yield put(authActions.loginAttempt.success(response));
    }
  } catch (error: any) {
    yield put(authActions.loginAttempt.error(error));
  }
}

function* signUp(state: { type: string; payload: IRegisterParams }) {
  try {
    yield put(authActions.registerUser.pending);
    const response: registrationResponse = yield call(api.signUpRequest, state.payload);
    const user: User = {
      access: response.access
    }
    yield localStorage.setItem('user', JSON.stringify(user));
    yield put(authActions.registerUser.success(response));
  } catch (error: any) {
    yield put(authActions.registerUser.error(error.message));
  }
}

function* logout() {
  yield call([localStorage, 'removeItem'], 'user');
  yield call([localStorage, 'removeItem'], 'userObj');
  yield call([localStorage, 'removeItem'], 'accessToken');
  yield put(authActions.logoutSucceed());
}

function* getUser(state: { type: string; payload: IGetUser }) {
  try {
    const user: User = yield call(api.getUser, { user_id: state.payload.user_id });
    if (user) {
      const accessTokenUser: User = yield getItemFromLocalStorage('user');
      yield (user.access = accessTokenUser.access);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));
    }
  } catch (err: any) {
    yield console.log('err', err.message);
  }
}

function* uploadProfile(state: { type: string; payload: IGetUser }) {
  try {
    const user: User = yield call(api.getUser, { user_id: state.payload.user_id });
    if (user) {
      const accessTokenUser: User = yield getItemFromLocalStorage('user');
      yield (user.access = accessTokenUser.access);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));
    }
  } catch (err: any) {
    yield console.log('err', err.message);
  }
}

function* verifyEmail(state: { type: string; payload: IEmailVerificationParams }) {
  try {
    yield put(authActions.verifyEmail.pending(state.payload.email));
    const response: verifyEmailResponse = yield call(api.emailVerificationRequest, state.payload);
    if (response) {
      yield console.log('Response : ', response);
      yield put(authActions.verifyEmail.success(response));
    }
  } catch (err: any) {
    yield put(authActions.verifyEmail.error(err.message));
  }
}

function* verifyOTP(state: { type: string; payload: IOTPVerificationParams }) {
  try {
    yield put(authActions.verifyOTP.pending(state.payload));
    const response: verifyOTPResponse = yield call(api.otpVerificationRequest, state.payload);
    if (response) {
      yield put(authActions.verifyOTP.success(response));
    }
  } catch (err: any) {
    yield put(authActions.verifyOTP.error(err.message));
  }
}

function* updateProfile(state: { type: string; payload: IProfileParams }) {
  try {
    yield put(authActions.updateProfile.pending(state.payload));
    const response: verifyOTPResponse = yield call(api.updateProfile, state.payload);
    if (response) {
      const userData: User = yield call(api.getProfile);
      const user = JSON.parse(localStorage.getItem('user') || '');
      userData.access = user.access;
      userData.refresh = user.refresh;
      yield localStorage.setItem('user', JSON.stringify(userData));
      yield put(authActions.updateProfile.success(response));

    }
  } catch (err: any) {
    yield put(authActions.updateProfile.error(err.message));
  }
}

function* deleteProfile() {
  try {
    yield put(authActions.deleteProfile.pending);
    const response: ApiResponse = yield call(api.deleteProfile);
    if (response) {
      yield console.log('Res : ', response);
      yield call([localStorage, 'removeItem'], 'user');
      yield call([localStorage, 'removeItem'], 'accessToken');
      yield put(authActions.logoutSucceed());
    }
  } catch (err: any) {
    yield put(authActions.deleteProfile.error(err.message));
  }
}

function* createPublicProfile(state: { type: string; payload: ICreatePublicProfileParams }) {
  try {
    yield console.log('State : ', state);
    yield put(authActions.createPublicProfile.pending);
    const response: ApiResponse = yield call(api.createPublicProfile, state.payload);
    if (response) {
      yield put(authActions.createPublicProfile.success(response));
    }
  } catch (err: any) {
    yield put(authActions.createPublicProfile.error(err.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.VERIFY_EMAIL_CALL, verifyEmail),
    takeEvery(ACTIONS.VERIFY_OTP_CALL, verifyOTP),
    takeEvery(ACTIONS.LOGIN_CALL, login),
    takeEvery(ACTIONS.AUTH_LOGOUT_BEGIN, logout),
    takeEvery(ACTIONS.SIGNUP_CALL, signUp),
    takeEvery(ACTIONS.GET_USER, getUser),
    takeEvery(ACTIONS.UPLOAD_PROFILE_BEGIN, uploadProfile),
    takeEvery(ACTIONS.UPDATE_PROFILE_BEGIN, updateProfile),
    takeEvery(ACTIONS.DELETE_PROFILE_BEGIN, deleteProfile),
    takeEvery(ACTIONS.CREATE_PUBLIC_PROFILE_BEGIN, createPublicProfile),
  ]);
}
