import React, {FC, useEffect, useState} from 'react';
import userImage from 'assets/images/image1.png';
import closeImage from 'assets/svg/close.svg';
import ChatHeader from 'components/chatContent/chatHeader/ChatHeader';
import ChatFooter from 'components/chatContent/chatFooter/ChatFooter';
import Styles from 'components/chatContent/ChatContent.module.scss';
import MessageBox from './messageBox/MessageBox';
// import Loader from 'components/loader/loader';

interface ChatContentProps {
  viewReplies: Function;
  replyItems: Function;
  chatInfo: Function;
  chats: any;
  selected: any;
  actions: boolean;
}
const ChatContent: FC<ChatContentProps> = (props: ChatContentProps) => {
  const { viewReplies, replyItems, chatInfo, chats, selected, actions } = props;
  const [typing, setTyping] = useState(false);
  const [images, setImages] = useState<any>([]);
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [chatContent] = useState(chats);
  useEffect(() => {}, [images]);
  return (
    <>
      <ChatHeader name={selected.topic} chatInfo={chatInfo} />
      <div className={`customScrollBar ${Styles.chatContentWrapper}`}>
        {/*{chats.length === 0 && <div className={Styles.loader}><Loader /></div>}*/}
        {chats.length > 0 && chats !== null && chats.map((item: any, index: number) => (
          <MessageBox selected={selected} item={item} replyItems={replyItems} type='' viewReplies={viewReplies} key={index} actions={actions} />
        ))}
      </div>
      {images.length > 0 && (
        <div className={Styles.attachedMedia}>
          {
            images.map((i: string, index: number) => (
              <div className={`position-relative ${Styles.attachedInnerMedia}`} key={index} >
                <img src={i} className={Styles.attachedFile} alt="attached-file" />
                <div className={Styles.closeIconWrapper}>
                  <img src={closeImage} alt="close-icon" />
                </div>
              </div>
            ))
          }
        </div>
      )}
      {typing ? (
        <span className={`px-3 fontFifteen input-color font-weight-normal ${Styles.typingUser}`}>{`${user.name} is typing…`}</span>
      ) : (
          <div className="pb-2">&nbsp;</div>
      )}
      <ChatFooter images={(e: string) => setImages([...images, e])} chats={chatContent} typing={setTyping} selected={selected} />
    </>
  );
};
export default ChatContent;
