import {
  IInvitePeopleParams,
  IDateTimeParams,
  ILocationParams,
  IFeedBackParams,
} from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import {
  URL_INVITE_PEOPLE,
  URL_NOTIFICATION_SETTING,
  URL_DATE_TIME_FORMAT,
  URL_SET_LOCATION,
  URL_FEEDBACK,
} from './URL';

export const api = {
  invitePeopleRequest(params: IInvitePeopleParams) {
    return apiCall.post({ url: URL_INVITE_PEOPLE, isAuthToken: true, params });
  },
  feedBackRequest(params: IFeedBackParams) {
    return apiCall.post({ url: URL_FEEDBACK, isAuthToken: true, params });
  },
  notificationSettingRequest() {
    return apiCall.get({ url: URL_NOTIFICATION_SETTING, isAuthToken: true, params : {} });
  },
  updateNotificationSettingRequest() {
    return apiCall.post({ url: URL_NOTIFICATION_SETTING, isAuthToken: true, params : {} });
  },
  dateTimeSettingRequest() {
    return apiCall.get({ url: URL_DATE_TIME_FORMAT, isAuthToken: true, params : {} });
  },
  updateDateTimeSettingRequest(params: IDateTimeParams) {
    return apiCall.post({ url: URL_DATE_TIME_FORMAT, isAuthToken: true, params });
  },
  createLocationRequest(params: ILocationParams) {
    return apiCall.post({ url: URL_SET_LOCATION, isAuthToken: true, params });
  },
};

export default api;
