import { call, put, all, takeEvery } from 'redux-saga/effects';
import { generalActions } from 'store/actions';
import ACTIONS from 'store/constants';
import ApiResponse from 'modules/response/response.types';
import {
  IInvitePeopleParams,
  IDateTimeParams,
  ILocationParams,
  IFeedBackParams,
} from 'modules/params/param.type';
import api from 'store/services/general.services';

function* invitePeople(state: { type: string; payload: IInvitePeopleParams }) {
  try {
    yield put(generalActions.invitePeople.pending);
    const response: ApiResponse = yield call(api.invitePeopleRequest, state.payload);
    if (response) {
      yield put(generalActions.invitePeople.success(response));
    }
  } catch (error: any) {
    yield put(generalActions.invitePeople.error(error.message));
  }
}

function* feedBack(state: { type: string; payload: IFeedBackParams }) {
  try {
    yield put(generalActions.feedBack.pending);
    const response: ApiResponse = yield call(api.feedBackRequest, state.payload);
    if (response) {
      yield put(generalActions.feedBack.success(response));
    }
  } catch (error: any) {
    yield put(generalActions.feedBack.error(error.message));
  }
}

function* getNotificationSetting() {
  try {
    yield put(generalActions.notificationSetting.pending);
    const response: ApiResponse = yield call(api.notificationSettingRequest);
    if (response) {
      yield put(generalActions.notificationSetting.success(response));
    }
  } catch (error: any) {
    yield put(generalActions.notificationSetting.error(error.message));
  }
}

function* updateNotificationSetting() {
  try {
    yield put(generalActions.updateNotificationSetting.pending);
    const response: ApiResponse = yield call(api.updateNotificationSettingRequest);
    if (response) {
      yield put(generalActions.updateNotificationSetting.success(response));
    }
  } catch (error: any) {
    yield put(generalActions.updateNotificationSetting.error(error.message));
  }
}

function* getDateTimeSetting() {
  try {
    yield put(generalActions.dateTimeSetting.pending);
    const response: ApiResponse = yield call(api.dateTimeSettingRequest);
    if (response) {
      yield put(generalActions.dateTimeSetting.success(response));
    }
  } catch (error: any) {
    yield put(generalActions.dateTimeSetting.error(error.message));
  }
}

function* updateDateTimeSetting(state: { type: string; payload: IDateTimeParams }) {
  try {
    yield put(generalActions.updateDateTimeSetting.pending);
    const response: ApiResponse = yield call(api.updateDateTimeSettingRequest, state.payload);
    if (response) {
      yield put(generalActions.updateDateTimeSetting.success(response));
    }
  } catch (error: any) {
    yield put(generalActions.updateDateTimeSetting.error(error.message));
  }
}

function* createLocation(state: { type: string; payload: ILocationParams }) {
  try {
    yield put(generalActions.createLocation.pending);
    const response: ApiResponse = yield call(api.createLocationRequest, state.payload);
    if (response) {
      yield put(generalActions.createLocation.success(response));
    }
  } catch (error: any) {
    yield put(generalActions.createLocation.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.INVITE_PEOPLE_BEGIN, invitePeople),
    takeEvery(ACTIONS.NOTIFICATION_SETTING_BEGIN, getNotificationSetting),
    takeEvery(ACTIONS.UPDATE_NOTIFICATION_SETTING_BEGIN, updateNotificationSetting),
    takeEvery(ACTIONS.DATE_TIME_SETTING_BEGIN, getDateTimeSetting),
    takeEvery(ACTIONS.UPDATE_DATE_TIME_SETTING_BEGIN, updateDateTimeSetting),
    takeEvery(ACTIONS.CREATE_LOCATION_BEGIN, createLocation),
    takeEvery(ACTIONS.FEED_BACK_BEGIN, feedBack),
  ]);
}