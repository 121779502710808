import { call, put, all, takeEvery } from 'redux-saga/effects';
import { conversationActions, authActions } from 'store/actions';
import ACTIONS from 'store/constants';
import ApiResponse from 'modules/response/response.types';
import {
  ICreateRoomParams,
  INearByRoomsParams,
  IURLIdParams,
} from 'modules/params/param.type';
import api from 'store/services/conversation.service';

function* createRoom(state: { type: string; payload: ICreateRoomParams }) {
  try {
    yield put(conversationActions.createRoom.pending);
    const response: ApiResponse = yield call(api.createRoomRequest, state.payload);
    if (response) {
      const data = JSON.stringify(response.data);
      const user = JSON.parse(localStorage.getItem('user') || '');
      const payload = {
        profile_id: user.pk.toString(),
        room_id: JSON.parse(data).pk.toString(),
      };
      yield put(authActions.createPublicProfileRequest(payload));
      yield put(conversationActions.createRoom.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.createRoom.error(error.message));
  }
}

function* getAllRooms() {
  try {
    yield put(conversationActions.fetchAllRooms.pending);
    const response: ApiResponse = yield call(api.getAllRoomsRequest);
    if (response) {
      yield put(conversationActions.fetchAllRooms.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.fetchAllRooms.error(error.message));
  }
}

function* getRoom() {
  try {
    yield put(conversationActions.fetchRoom.pending);
    const response: ApiResponse = yield call(api.getRoomRequest);
    if (response) {
      yield put(conversationActions.fetchRoom.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.fetchRoom.error(error.message));
  }
}

function* joinRoom(state: { type: string; payload: IURLIdParams }) {
  try {
    yield put(conversationActions.joinRoom.pending);
    const response: ApiResponse = yield call(api.joinRoomRequest, state.payload);
    if (response) {
      yield put(conversationActions.joinRoom.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.joinRoom.error(error.message));
  }
}

function* nearByRoom(state: { type: string; payload: INearByRoomsParams }) {
  try {
    yield put(conversationActions.nearbyRooms.pending);
    const response: ApiResponse = yield call(api.nearByRoomsRequest, state.payload);
    if (response) {
      yield put(conversationActions.nearbyRooms.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.nearbyRooms.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.CREATE_ROOM_BEGIN, createRoom),
    takeEvery(ACTIONS.NEARBY_ROOMS_BEGIN, nearByRoom),
    takeEvery(ACTIONS.ROOM_LIST_BEGIN, getAllRooms),
    takeEvery(ACTIONS.CREATE_ROOM_FETCH_BEGIN, getRoom),
    takeEvery(ACTIONS.JOIN_ROOM_BEGIN, joinRoom),
  ]);
}