import createSelector from 'utils/reselect';

const roomStateData = (state: any) => {
  const { conversations } = state;
  return conversations || {};
};

export const getLoading = createSelector(roomStateData, (conversations) => conversations.get('loading') || false);

export const getCreatedRoom = createSelector(roomStateData, (conversations) => conversations.get('createdRoom') || {});

export const getRooms = createSelector(roomStateData, (conversations) => conversations.get('rooms') || []);

export const getNearByRooms = createSelector(roomStateData, (conversations) => conversations.get('nearByRooms') || []);

export const roomCreated = createSelector(roomStateData, (conversations) => conversations.get('isRoomCreated'));

export const roomJoined = createSelector(roomStateData, (conversations) => conversations.get('isJoined'));
