import { connect} from 'react-redux';
import { verifyEmailRequest } from 'store/actions/auth.actions';
import { getEmailVerified, emailVerificationError, getLoader } from 'store/selector/auth.selector';
import EmailVerification from 'views/register/emailVerification/EmailVerification';

const mapDispatchToProps = { verifyEmail: verifyEmailRequest };

const mapStateToProps = (state: any) => {
  const isEmailVerified = getEmailVerified(state);
  const isLoading = getLoader(state);
  const error = emailVerificationError(state);
  return { isEmailVerified, error, isLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);