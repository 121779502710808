import { connect } from 'react-redux';
import ConversationsNearby from 'views/conversationsNearby/ConversationsNearby';
import { getAllRoomsRequest } from 'store/actions/conversation.actions';
import { nearbyRoomsRequest } from 'store/actions/conversation.actions';
import { createLocationRequest } from 'store/actions/general.actions';
import { joinRoomRequest } from 'store/actions/conversation.actions';
import { getRooms, getCreatedRoom, getNearByRooms, getLoading } from 'store/selector/conversation.selector';
import { getUser } from 'store/selector/auth.selector';

const mapDispatchToProps = {
  getRoom: getAllRoomsRequest,
  createLocation: createLocationRequest,
  nearbyRooms: nearbyRoomsRequest,
  joinRequest: joinRoomRequest
};

const mapStateToProps = (state: any) => {
  const rooms = getRooms(state);
  const createdRoom = getCreatedRoom(state);
  const allNearByRooms = getNearByRooms(state);
  const user = getUser(state);
  const loader = getLoading(state);
  return { rooms, createdRoom, allNearByRooms, user, loader };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsNearby);
