import BroadCast from 'views/BroadCast/BroadCast';
import { connect } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';

const mapStateToProps = (state: any) => {
  const user = getUser(state);
  return { user };
};

export default connect(mapStateToProps, null)(BroadCast);
