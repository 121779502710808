import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL, uploadBytesResumable, listAll, deleteObject } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyCoEw4_ZTtkeqwP4X4Zry3r3M4EaanYEi8',
  authDomain: 'socialplay-7edf4.firebaseapp.com',
  projectId: 'socialplay-7edf4',
  storageBucket: 'socialplay-7edf4.appspot.com',
  messagingSenderId: '945402121620',
  appId: '1:945402121620:web:bfb7c990755c8aadd6d381',
  measurementId: 'G-NXF2RGCB3Q',
  databaseURL: 'https://socialplay-7edf4-default-rtdb.firebaseio.com',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getDatabase(app);
const reference = ref;
export { db, storage, reference, getDownloadURL, uploadBytesResumable, listAll, deleteObject };

