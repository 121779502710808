import React, { FunctionComponent } from 'react';
import Styles from './Button.module.scss';
import Loader from '../loader/loader';

interface ButtonProps {
  label: string;
  cssClass?: string;
  iconClass?: string;
  clickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: boolean;
  span?: string;
  showLoading?: boolean;
  disabled?: boolean;
}

const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const { label, clickHandler, cssClass, icon, iconClass, span, showLoading, disabled } = props;
  return (
    <button
      disabled={disabled}
      onClick={clickHandler}
      type="button"
      className={`btn font-weight-bold text-uppercase position-relative ${Styles.singUpBtn} ${cssClass}`}
    >
      {showLoading && <Loader />}
      &nbsp;
      {label}
      {icon && (
        <i className={`fontFourteen pl-2 ${iconClass ? `${iconClass}` : 'fas fa-long-arrow-alt-right fontSixteen'}`} />
      )}
      {span && (
        <span className={Styles.moreDetails}>
          <br />
          {span}
        </span>
      )}
    </button>
  );
};
export default Button;
