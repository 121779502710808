import ACTIONS, { FETCH_STATUS } from 'store/constants';
import {
  ICreateRoomParams,
  IURLIdParams,
  INearByRoomsParams,
} from 'modules/params/param.type';

export const setSelectedBroadCast = (payload: any) => ({
  type: ACTIONS.SET_SELECTED_BROADCAST,
  payload,
});

export const getAllRoomsRequest = () => ({
  type: ACTIONS.ROOM_LIST_BEGIN,
});

export const fetchAllRooms = {
  pending: {
    type: ACTIONS.ROOM_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.ROOM_LIST.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.ROOM_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getRoomRequest = () => ({
  type: ACTIONS.CREATE_ROOM_FETCH_BEGIN,
});

export const fetchRoom = {
  pending: {
    type: ACTIONS.CREATE_ROOM_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CREATE_ROOM_FETCH.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_ROOM_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createRoomRequest = (payload: ICreateRoomParams) => ({
  type: ACTIONS.CREATE_ROOM_BEGIN,
  payload,
});

export const createRoom = {
  pending: {
    type: ACTIONS.CREATE_ROOM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CREATE_ROOM.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_ROOM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const joinRoomRequest = (payload: IURLIdParams) => ({
  type: ACTIONS.JOIN_ROOM_BEGIN,
  payload,
});

export const joinRoom = {
  pending: {
    type: ACTIONS.JOIN_ROOM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.JOIN_ROOM.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.JOIN_ROOM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const nearbyRoomsRequest = (payload: INearByRoomsParams) => ({
  type: ACTIONS.NEARBY_ROOMS_BEGIN,
  payload,
});

export const nearbyRooms = {
  pending: {
    type: ACTIONS.NEARBY_ROOMS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.NEARBY_ROOMS.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.NEARBY_ROOMS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};



