import React, { FunctionComponent } from 'react';
import Styles from 'components/customModal/CustomModal.module.scss';

interface CustomModalProps {
  closeDialog?: (event: React.FormEvent<HTMLDivElement>) => void;
  cssClass?: string;
  wrapperClass?: string;
  heightClass?: string;
  title?: string;
  children: any;
}

const CustomModal: FunctionComponent<CustomModalProps> = (props: CustomModalProps) => {
  const { children, cssClass, closeDialog, title, wrapperClass, heightClass } = props;
  return (
    <>
      <section className={`${Styles.modalWrapper} ${wrapperClass}`}>
        <div className={Styles.modalContent}>
          <div className={Styles.modalHeader}>
            <h4 className={`font-weight-600 ${Styles.modalTitle} text-center`}>{title}</h4>
          </div>
          <div className={`${Styles.modalBody} ${cssClass} ${heightClass} customBodyScroll`}>{children}</div>
        </div>
      </section>
      <div className={Styles.modalBgLayer} onClick={closeDialog} onKeyPress={closeDialog} role="button" tabIndex={0} />
    </>
  );
};

export default CustomModal;
