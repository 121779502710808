import { db, storage, reference, getDownloadURL, uploadBytesResumable, deleteObject } from 'utils/firebase-config';
import { ref, onValue, set, update } from 'firebase/database';
import { useEffect, useState } from 'react';

export const createRoom = async (room: any) => {
  const createRoom: any = ref(db, `rooms/${room.roomId}`);
  try {
    await set(createRoom, {
      roomId: room.roomId,
      topic: room.topic,
      host: {
        id: room.host.pk,
        name: room.host.name,
        isSuperHost: room.host.is_super_host,
        image: room.host.profile,
      },
      created_at: room.created_at,
      isDeleted: false,
      call_status: false,
    });
  } catch (err) {
    console.log(err);
  }
};

export const useGetMessages = (pk: number) => {
  const [chat, setChat] = useState<any>([]);
  useEffect(() => {
    onValue(ref(db, `chats/${pk}/messages`), (querySnapshot: any) => {
      const messages: any = [];
      querySnapshot.forEach((doc: any) => {
        messages.push(doc.val());
      });
      if (messages.length > 0) {
        messages.sort((i: any, j: any) => (i.time < j.time ? -1 : 0));
        setChat(messages);
      } else {
        setChat([]);
      }
    });
  }, [pk]);
  return chat;
};

export const sendFCMMessage = async (message: any) => {
  const roomId = message.roomId;
  const messages: any = ref(db, `chats/${roomId}/messages/${message.id}`);
  try {
    await set(messages, {
      id: message.id,
      sender: message.sender,
      receiver: message.receiver,
      message: message.message,
      time: message.time,
      is_delete: message.is_delete,
      attachments: message.attachments,
      threads: message.threads,
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const sendThreadMessage = async (message: any) => {
  let findMsg: any = {};
  let length = 0;
  const roomId = message.roomId;
  onValue(ref(db, `chats/${roomId}/messages`), (querySnapshot: any) => {
    querySnapshot.forEach((doc: any) => {
      if (doc.val().id === message.parentId) {
        findMsg = doc.val();
      }
    });
  });
  const isThreadsExist = findMsg.hasOwnProperty('threads');
  if (isThreadsExist) {
    onValue(ref(db, `chats/${roomId}/messages/${message.parentId}/threads`), (querySnapshot: any) => {
      const obj = querySnapshot.val();
      length = obj === null ? 0 : obj.length;
    });
  }
  if (length === 0) {
    const messages: any = ref(db, `chats/${roomId}/messages/${message.parentId}`);
    try {
      await update(messages, {
        threads: [
          {
            id: message.id,
            sender: message.sender,
            receiver: message.receiver,
            message: message.message,
            time: message.time,
            is_delete: message.is_delete,
            attachments: message.attachments,
          }
        ]
      });
    } catch (err) {
      console.log(err);
    }
  } else {
    const messages = ref(db, `chats/${roomId}/messages/${message.parentId}/threads/${length}`);
    try {
      await set(messages, {
        id: message.id,
        sender: message.sender,
        receiver: message.receiver,
        message: message.message,
        time: message.time,
        is_delete: message.is_delete,
        attachments: message.attachments,
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
};

export const deleteMessage = async (path: any) => {
  const message: any = ref(db, `chats/${path}`);
  try {
    await set(message, {});
  } catch (err) {
    console.log(err);
  }
};

export const deleteImage = async (path: any) => {
  const desertRef = reference(storage, `${path}`);
  deleteObject(desertRef).then(() => {
    // File deleted successfully
  }).catch((error) => {
    // Uh-oh, an error occurred!
  });
};

export const getImages = async (roomId: string, attachmentId: string) => {
  const images: any = [];
  try {
    const url1 = await getDownloadURL(reference(storage, `/${roomId}/${attachmentId}`));
    if (url1) images[0] = url1;
    return images;
  } catch (err) {}
  return images;
};

export const uploadImage = async (message: any, file: any) => {
  try {
    return await uploadBytesResumable(reference(storage, `/${message.roomId}/${message.attachments[0].id}`), file);
  } catch (err) {}
  return '';
};

export const updateCallStatus = async (room: any) => {
  const getRoom: any = ref(db, `rooms/${room.roomId}`);
  try {
    await update(getRoom, {
      call_status: room.status
    });
  } catch (err) {
    console.log(err);
  }
};

export const useGetCallStatus = (roomId: any) => {
  const getRoom: any = ref(db, `rooms/${roomId}`);
  const [room, setRoom] = useState<any>({});
  useEffect(() => {
    onValue(getRoom, (querySnapshot: any) => {
      setRoom(querySnapshot.val());
    });
  }, [roomId]);
  return room;
};


